import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";

import { Button } from "@material-ui/core";

import Logger from "utils/logger";
import { userListBrowserMapStateToProps } from "mapToProps/userEditor";
import { Dialog, TableGrid } from "components";
import ContentContainer from "modules/ContentContainer";
import ActionFormModal from "../../ActionFormModal";
import * as userActions from "actions/userEditor";
import "./styles.scss"; // need to leverage the same selector actions from story

class UserListBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      addModalOpen: false,
      deleteModalOpen: false,
      selected: []
    };

    // NOTE: the bindings allow for use of this reference
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.proceedToAdd = this.proceedToAdd.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  componentDidUpdate() {
    // if (this.props.navigateToNew) {
    //   this.props.goToScene(this.props.navigateToNew);
    // }
  }

  openDeleteModal() {
    Logger.debug("[USER LIST BROWSER] openDeleteModal");
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });
    const userIDs = [];
    _map(this.state.selected, item => {
      userIDs.push(item.id);
    });
    this.props.handleDeleteRequest(userIDs);
  }

  handleRowSelection(rows, items) {
    Logger.debug({ rows, items }, "[UserListBrowser] - handleRowSelection");
    this.setState({ selected: items });
  }

  openAddModal() {
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  proceedToAdd(data) {
    this.setState({ addModalOpen: false });
    this.props.handleAddRequest(data);
  }

  render() {
    const { sidebarMode, currentUserID } = this.props;

    const header = {
      1: {
        id: 1,
        label: "First Name"
      },
      2: {
        id: 2,
        label: "Last Name"
      },
      3: {
        id: 3,
        label: "Email"
      },
      4: {
        id: 4,
        label: "Privileges"
      },
      5: {
        id: 5,
        label: "Active"
      },
      6: {
        id: 6,
        label: ""
      }
    };

    const topBar = (
      <div className="filters-bar-container">
        {this.state.selected.length > 0 ? (
          <Button
            id="delete-button"
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openDeleteModal();
            }}
          >
            DELETE
          </Button>
        ) : (
          <Button
            id="new-button"
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openAddModal();
            }}
          >
            NEW
          </Button>
        )}
      </div>
    );

    const headerStyle = {
      backgroundColor: "#f2f2f2"
    };

    const addForm = {
      firstname: {
        type: "text",
        isRequired: true,
        label: "First Name",
        param: "firstname",
        value: "",
        inlineLabel: true,
        order: 1
      },
      lastname: {
        type: "text",
        isRequired: true,
        label: "Last Name",
        param: "lastname",
        value: "",
        inlineLabel: true,
        order: 2
      },
      email: {
        type: "text",
        isRequired: true,
        label: "Email",
        param: "email",
        value: "",
        inlineLabel: true,
        order: 3
      },
      password: {
        type: "password",
        isRequired: true,
        label: "Password",
        param: "password",
        value: "",
        inlineLabel: true,
        order: 4
      },
      groupID: {
        type: "select",
        isRequired: true,
        label: "Privileges",
        options: [
          {
            label: "Select...",
            value: "0"
          },
          {
            label: "Administrator",
            value: "575588b96a01a9ceb5f760d5"
          },
          {
            label: "Publisher",
            value: "5ce1c5bf8d99c90ad78726b3"
          },
          {
            label: "Editor",
            value: "575586f06a01a9ceb5f7601e"
          }
        ],
        value: "0",
        param: "groupID",
        order: 5
      }
    };

    const sortedUsers = _sortBy(this.props.users, ["lastname", "firstname"]);
    const tableGridItems = {};
    _map(sortedUsers, user => {
      tableGridItems[user._id] = {
        selectable: user._id !== currentUserID ? true : false,
        id: user._id,
        columns: {
          1: { id: 1, label: user.firstname },
          2: { id: 2, label: user.lastname },
          3: { id: 3, label: user.email },
          4: {
            id: 4,
            label:
              user.groupID === "575588b96a01a9ceb5f760d5"
                ? "Administrator"
                : user.groupID === "5ce1c5bf8d99c90ad78726b3"
                ? "Publisher"
                : "Editor"
          },
          5: { id: 5, label: user.active ? "Yes" : "No" }
        }
      };
    });

    return (
      <div className="user-list-browser">
        <ContentContainer
          backgroundColor="white"
          isLoading={false}
          className="content-container"
          sidebarMode={sidebarMode}
          topBar={topBar}
        >
          {tableGridItems ? (
            <TableGrid
              items={tableGridItems}
              header={header}
              handleRowSelectionFunc={this.handleRowSelection}
              idNode="id"
              headerStyle={headerStyle}
              multiSelectable
              sortable
              editAction={this.props.editUser}
            />
          ) : null}
        </ContentContainer>
        <ActionFormModal
          open={this.state.addModalOpen}
          closeAction={this.closeAddModal}
          handleSave={this.proceedToAdd}
          title={"Add New User"}
          form={addForm}
        />
        <Dialog
          content={"Once deleted, these users cannot be restored"}
          handleConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          handleCancel={this.closeDeleteModal}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title={"Delete User?"}
        />
        <Dialog
          content={`An error occurred adding the user. ${this.props.addError}`}
          confirmLabel="OK"
          handleConfirm={this.props.handleAddErrorConfirm}
          handleCancel={this.props.handleAddErrorConfirm}
          bodyStyle={{ paddingTop: 20 }}
          modal={false}
          open={this.props.addError === false ? false : true}
          title={"Error Adding User"}
        />
        <Dialog
          content={`An error occurred deleting a user. ${this.props.deleteError}`}
          confirmLabel="OK"
          handleConfirm={this.props.handleDeleteErrorConfirm}
          handleCancel={this.props.handleDeleteErrorConfirm}
          bodyStyle={{ paddingTop: 20 }}
          modal={false}
          open={this.props.deleteError === false ? false : true}
          title={"Error Deleting User"}
        />
      </div>
    );
  }
}

UserListBrowser.defaultProps = {
  isLoading: false
};

UserListBrowser.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return userListBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, {
    editUser: userID => {
      const url = `users/${userID}`;
      ownProps.history.push(url);
    },
    handleAddRequest: userData => {
      dispatchProps.handleAddRequest(userData);
    },
    handleAddErrorConfirm: () => {
      dispatchProps.handleAddErrorConfirm();
    },
    goToUser: userID => {
      ownProps.history.push(`account/users/${userID}`);
    },
    handleDeleteRequest: userIDs => {
      Logger.debug({ userIDs }, "[UserListBrowser] - handleDeleteRequest");
      dispatchProps.handleDeleteRequest(userIDs);
    },
    handleDeleteErrorConfirm: () => {
      dispatchProps.handleDeleteErrorConfirm();
    }
  });
}

export default connect(
  mapStateToProps,
  userActions,
  mergeProps
)(UserListBrowser);
